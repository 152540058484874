// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  background: #121212;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Manual set style properties to show overflow TableChart values without cutoff */
.MuiDataGrid-cell--textLeft {
  max-width: 600px !important;
}

.MuiDataGrid-virtualScroller {
  overflow-x: auto !important;
}

.Menu-Options {
  color: #6656f8;
  cursor: pointer;
  font-size: 20px;
}

.Menu-Options:hover {
  text-decoration: underline;
}

.Hover-Underline:hover {
  text-decoration: underline !important;
}

.rotated {
  transform: rotate(180deg);
}

input[type="file"] {
  display: none;
}

.round-image {
  border-radius: 2px;
}

.PrivatePickersSlideTransition-root {
  min-height: 200px !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT;2DACyD;EACzD,mCAAmC;EACnC,kCAAkC;EAClC,WAAW;AACb;;AAEA;EACE,+EAA+E;AACjF;;AAEA,kFAAkF;AAClF;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["html,\nbody {\n  background: #121212;\n}\n\nbody {\n  margin: 0;\n  font-family: Roboto, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\",\n    \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  color: #fff;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n/* Manual set style properties to show overflow TableChart values without cutoff */\n.MuiDataGrid-cell--textLeft {\n  max-width: 600px !important;\n}\n\n.MuiDataGrid-virtualScroller {\n  overflow-x: auto !important;\n}\n\n.Menu-Options {\n  color: #6656f8;\n  cursor: pointer;\n  font-size: 20px;\n}\n\n.Menu-Options:hover {\n  text-decoration: underline;\n}\n\n.Hover-Underline:hover {\n  text-decoration: underline !important;\n}\n\n.rotated {\n  transform: rotate(180deg);\n}\n\ninput[type=\"file\"] {\n  display: none;\n}\n\n.round-image {\n  border-radius: 2px;\n}\n\n.PrivatePickersSlideTransition-root {\n  min-height: 200px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
